import { Injectable, inject } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';

import { CookieService } from './cookie.service';
import { StorageService } from './storage.service';

/**
 * Token service.
 */
@Injectable({
	providedIn: 'root',
})
export class TokenService {
	/**
	 * Cookie service.
	 * @private
	 */
	private readonly cookieService = inject(CookieService);

	private readonly storageService = inject(StorageService);

	/**
	 * JWT helper service.
	 */
	jwtHelper = new JwtHelperService();

	/**
	 * Get refresh token.
	 *
	 * @return Refresh token.
	 */
	getRefreshToken(): string {
		return this.cookieService.get('token-refresh');
	}

	/**
	 * Get remember.
	 */
	getRemember(): string {
		return this.storageService.get('remember');
	}

	/**
	 * Get token.
	 *
	 * @return Token.
	 */
	getToken(): string {
		return this.cookieService.get('token');
	}

	/**
	 * Get token data.
	 */
	getTokenData() {
		return this.jwtHelper.decodeToken(this.getToken());
	}

	/**
	 * Is admin user.
	 */
	isAdminUser(): boolean {
		return this.getTokenData()?.groupInternalReference === 'ADMIN';
	}

	/**
	 * Is company admin user.
	 *
	 */
	isCompanyAdminUser(): boolean {
		return this.getTokenData()?.groupInternalReference === 'COMPANY_ADMIN';
	}

	/**
	 * Is token expired.
	 *
	 * @param token Token.
	 */
	isTokenExpired(token?: string): Promise<boolean> | boolean {
		return this.jwtHelper.isTokenExpired(token ?? this.getToken());
	}

	/**
	 * Remove refresh token.
	 */
	removeRefreshToken(): void {
		this.cookieService.delete('token-refresh', '/');
	}

	/**
	 * Remove token.
	 */
	removeToken(): void {
		this.cookieService.delete('token', '/');
	}

	/**
	 * Remove tokens.
	 */
	removeTokens(): void {
		this.removeToken();
		this.removeRefreshToken();
		this.setRemember(false);
	}

	/**
	 * Set refresh token.
	 *
	 * @param token Refresh token.
	 * @param path  Token cookie path (Optional).
	 */
	setRefreshToken(token: string, path?: string): void {
		this.cookieService.set('token-refresh', token, undefined, path ?? '/');
	}

	/**
	 * Remember me.
	 *
	 * @param status Remember status (true|false)/
	 */
	setRemember(status: boolean): void {
		if (status) {
			this.storageService.set('remember', this.getRefreshToken());
		} else {
			this.storageService.remove('remember');
		}
	}

	/**
	 * Set token.
	 *
	 * @param token Token.
	 * @param path  Token cookie path (Optional).
	 */
	setToken(token: string, path?: string): void {
		this.cookieService.set('token', token, undefined, path ?? '/');
	}
}
