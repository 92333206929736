import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

/**
 * Same site type.
 */
export type SameSite = 'Lax' | 'None' | 'Strict';

/**
 * Cookie options.
 */
export interface CookieOptions {
	domain?: string;
	expires?: Date | number;
	path?: string;
	sameSite?: SameSite;
	secure?: boolean;
}

/**
 * Cookie service.
 */
@Injectable({
	providedIn: 'root',
})
export class CookieService {
	/**
	 * Is document accessible.
	 * @private
	 */
	private readonly documentIsAccessible: boolean;

	/**
	 * Service constructor.
	 *
	 * @param document   Document.
	 * @param platformId Platform id.
	 */
	constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: any,
	) {
		this.documentIsAccessible = isPlatformBrowser(this.platformId);
	}

	/**
	 * Get cookie regular expression.
	 * @param name cCookie name.
	 * @private
	 */
	private static getCookieRegExp(name: string): RegExp {
		const escapedName: string = name.replaceAll(/([$()*+,.;=?[\]^{|}])/gi, '\\$1');
		return new RegExp(`(?:^${escapedName}|;\\s*${escapedName})=(.*?)(?:;|$)`, 'g');
	}

	/**
	 * Gets the unencoded version of an encoded component of a Uniform Resource Identifier (URI).
	 * @param encodedURIComponent  A value representing an encoded URI component.
	 * @private
	 */
	private static safeDecodeURIComponent(encodedURIComponent: string): string {
		try {
			return decodeURIComponent(encodedURIComponent);
		} catch {
			return encodedURIComponent;
		}
	}

	/**
	 * Return `true` if {@link Document} is accessible, otherwise return `false`.
	 *
	 * @param name Cookie name.
	 */
	check(name: string): boolean {
		if (!this.documentIsAccessible) {
			return false;
		}
		const encodedName = encodeURIComponent(name);
		const regExp: RegExp = CookieService.getCookieRegExp(encodedName);
		return regExp.test(this.document.cookie);
	}

	/**
	 * Delete cookie by name.
	 *
	 * @param name     Cookie name.
	 * @param path     Cookie path.
	 * @param domain   Cookie domain.
	 * @param secure   Cookie secure flag.
	 * @param sameSite Cookie sameSite flag - https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
	 */
	delete(
		name: string,
		path?: CookieOptions['path'],
		domain?: CookieOptions['domain'],
		secure?: CookieOptions['secure'],
		sameSite: SameSite = 'Lax',
	): void {
		if (!this.documentIsAccessible) {
			return;
		}
		const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
		this.set(name, '', {
			domain,
			expires: expiresDate,
			path,
			sameSite,
			secure,
		});
	}

	/**
	 * Delete all cookies.
	 *
	 * @param path     Cookie path.
	 * @param domain   Cookie domain.
	 * @param secure   Is the Cookie secure.
	 * @param sameSite Is the cookie same site.
	 */
	deleteAll(
		path?: CookieOptions['path'],
		domain?: CookieOptions['domain'],
		secure?: CookieOptions['secure'],
		sameSite: SameSite = 'Lax',
	): void {
		if (!this.documentIsAccessible) {
			return;
		}

		const cookies = this.getAll();

		for (const cookieName of Object.keys(cookies)) {
			if (Object.prototype.hasOwnProperty.call(cookies, cookieName)) {
				this.delete(cookieName, path, domain, secure, sameSite);
			}
		}
	}

	/**
	 * Get cookies by name.
	 *
	 * @param name Cookie name.
	 */
	get(name: string): string {
		if (this.documentIsAccessible && this.check(name)) {
			const encodedName = encodeURIComponent(name);

			const regExp: RegExp = CookieService.getCookieRegExp(encodedName);
			const result: RegExpExecArray | null = regExp.exec(this.document.cookie);

			return result && result[1] ? CookieService.safeDecodeURIComponent(result[1]) : '';
		}
		return '';
	}

	/**
	 * Get all cookies in JSON format.
	 */
	getAll(): { [key: string]: string } {
		if (!this.documentIsAccessible) {
			return {};
		}

		const cookies: { [key: string]: string } = {};
		const { document } = this;

		if (document.cookie && document.cookie !== '') {
			for (const currentCookie of document.cookie.split(';')) {
				const [cookieName, cookieValue] = currentCookie.split('=');
				cookies[CookieService.safeDecodeURIComponent(cookieName.replace(/^ /, ''))] =
					CookieService.safeDecodeURIComponent(cookieValue);
			}
		}

		return cookies;
	}

	/**
	 * Set cookie based on provided information.
	 *
	 * @param name             Cookie name.
	 * @param value            Cookie value.
	 * @param expiresOrOptions Number of days until the cookies expires or an actual `Date`.
	 * @param path             Cookie path.
	 * @param domain           Cookie domain.
	 * @param secure           Secure flag.
	 * @param sameSite         OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`.
	 */
	set(
		name: string,
		value: string,
		expiresOrOptions?: CookieOptions | CookieOptions['expires'],
		path?: CookieOptions['path'],
		domain?: CookieOptions['domain'],
		secure?: CookieOptions['secure'],
		sameSite?: SameSite,
	): void {
		if (!this.documentIsAccessible) {
			return;
		}

		if (typeof expiresOrOptions === 'number' || expiresOrOptions instanceof Date || path || domain || secure || sameSite) {
			const optionsBody = {
				domain,
				expires: expiresOrOptions as CookieOptions['expires'],
				path,
				sameSite: sameSite || 'Lax',
				secure,
			};

			this.set(name, value, optionsBody);
			return;
		}

		let cookieString: string = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;
		const options = expiresOrOptions || {};

		if (options.expires) {
			if (typeof options.expires === 'number') {
				const dateExpires: Date = new Date(Date.now() + options.expires * 1000 * 60 * 60 * 24);

				cookieString += `expires=${dateExpires.toUTCString()};`;
			} else {
				cookieString += `expires=${options.expires.toUTCString()};`;
			}
		}

		if (options.path) {
			cookieString += `path=${options.path};`;
		}

		if (options.domain) {
			cookieString += `domain=${options.domain};`;
		}

		if (options.secure === false && options.sameSite === 'None') {
			options.secure = true;
			console.warn(`Cookie ${name} was forced with secure flag because sameSite=None.`);
		}

		if (options.secure) {
			cookieString += 'secure;';
		}

		if (!options.sameSite) {
			options.sameSite = 'Lax';
		}

		cookieString += `sameSite=${options.sameSite};`;

		this.document.cookie = cookieString;
	}
}
